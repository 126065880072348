/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, message, Image, Spin, Row, Col, Typography, Button } from 'antd';
import { popUpEidtInfoAndCloder, settingdatasetInfoSate } from '../../atom';
import { useRecoilState } from 'recoil';
import ColorPickerCustom from '../base/colorPicker';
import ImgCrop from 'antd-img-crop';
import { getBase64 } from '../../utils/sorting';
import imageIcon from '../../assets/images/imageicon.svg';
import axiosClient from '../../api/axiosClient';
import { autoUpdateNewName, autoUpdateNewNameGpts } from '../../api/service';

const layoutHeading = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};
const layoutItem = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 14,
    },
};

const ModalEditInfoAndColorEmbeding = () => {
    const [form] = Form.useForm();
    const [openPopup, setOpenPopup] = useRecoilState(popUpEidtInfoAndCloder);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState({ get: false, patch: false });
    const [, setDataInfoBot] = useRecoilState(settingdatasetInfoSate);

    const bot_id = openPopup?.data?.id;

    const handleChange = ({ fileList: newFileList }) => {
        if (newFileList.length > 0) {
            setUploadedImage(newFileList[0].originFileObj);
        } else {
            setUploadedImage(null);
            setPreviewImage(openPopup.data?.preview_logo || '');
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const beforeCrop = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Chỉ hỗ trợ JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Ảnh phải nhỏ hơn 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const hanldeClose = () => {
        setOpenPopup({ open: false, data: null });
    };

    useEffect(() => {
        setLoading((prev) => ({ ...prev, get: true }));
        const loadingTimout = setTimeout(() => setLoading((prev) => ({ ...prev, get: false })), 100);

        if (openPopup?.data?.preview_logo) {
            setPreviewImage(openPopup?.data?.preview_logo);
        }
        if (openPopup?.data) {
            form.setFieldsValue(openPopup.data);
            form.setFieldsValue({ boxChat_title: openPopup.data.name });
            if (openPopup.data.name !== openPopup.data.boxChat_title) {
                openPopup.type === 'gpts' ? autoUpdateNewNameGpts(openPopup.data) : autoUpdateNewName(openPopup.data);
            }
        }

        return () => {
            clearTimeout(loadingTimout);
        };
    }, [openPopup.data]);

    const onFishnish = async (values, allValues) => {
        setLoading((prev) => ({ ...prev, patch: true }));
        const payload = new FormData();

        for (const key in values) {
            payload.append(key, values[key]);
        }
        if (uploadedImage) {
            payload.append('preview_logo', uploadedImage);
        }
        const url = openPopup.type === 'gpts' ? `/assistant-profile/${openPopup?.data?.idProfle}/` : `bots/${bot_id}/`;
        try {
            const res = await axiosClient.patch(url, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.status <= 205) {
                setDataInfoBot((prevDataInfoBot) => ({
                    ...prevDataInfoBot,
                    preview_logo: res.data?.preview_logo,
                    boxChat_title: res.data?.boxChat_title,
                    boxChat_subTitle: res.data?.boxChat_subTitle,
                    boxChat_backgroundColor: res.data?.boxChat_backgroundColor,
                    preview_title_color: res.data.preview_title_color,
                    preview_send_button_color: res.data?.preview_send_button_color,
                    boxChat_colorWidgetBg: res.data?.boxChat_colorWidgetBg,
                    userM_backgroundColor: res.data?.userM_backgroundColor,
                    userM_color: res.data?.userM_color,
                    botM_color: res.data?.botM_color,
                    botM_backgroundColor: res.data?.botM_backgroundColor,
                }));
                message.success('Đã lưu chỉnh sửa');
                setLoading((prev) => ({ ...prev, patch: false }));
                hanldeClose();
                return;
            }
            if (res.data?.detail) {
                message.error(res.data?.detail);
            } else {
                message.error('Đã có lỗi xảy ra');
            }
            setLoading((prev) => ({ ...prev, patch: false }));
        } catch (error) {
            message.warning('Chức năng đang bảo trì');
            setLoading((prev) => ({ ...prev, patch: false }));
        }
    };

    return (
        <Modal
            title="Tuỳ chỉnh giao diện"
            centered
            open={openPopup.open}
            onCancel={hanldeClose}
            footer={[
                <Button onClick={hanldeClose}>Huỷ bỏ</Button>,
                <Button loading={loading?.patch} onClick={() => form.submit()} className="bg-primary" type="primary">
                    Cập nhật
                </Button>,
            ]}
            width={800}
        >
            <div
                className="pt-2 h-[calc(100vh-12rem)] overflow-y-auto thin-scroll required:"
                style={{
                    minHeight: 400,
                }}
            >
                {loading.get ? (
                    <div className="absolute top-1/2 right-1/2">
                        <Spin />
                    </div>
                ) : (
                    <Form form={form} onFinish={onFishnish} labelAlign="left" name="edit-color">
                        <Form.Item
                            {...layoutHeading}
                            label="Ảnh đại điện"
                            name="preview_logo"
                            tooltip="Ảnh đại diện của bot"
                        >
                            <ImgCrop
                                modalOk="Xác nhận"
                                modalCancel="Huỷ bỏ"
                                modalTitle="Tinh chỉnh"
                                rotationSlider
                                beforeCrop={beforeCrop}
                            >
                                <Upload
                                    defaultFileList={[{ uid: '1', name: '', status: 'done', url: previewImage }]}
                                    listType="picture-card"
                                    accept=".png, .jpg, .jpeg"
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    maxCount={1}
                                >
                                    <button type="button">
                                        <Image preview={false} width={30} src={imageIcon} alt="image" />
                                        <div className="!mt-0">Tải lên</div>
                                    </button>
                                </Upload>
                            </ImgCrop>

                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            {...layoutHeading}
                            label="Tiêu đề chính"
                            name="boxChat_title"
                            tooltip="Tên của bot khi hiển thị chat với người dùng"
                        >
                            <Input showCount maxLength={200} placeholder="Nhập tiêu đề chính" />
                        </Form.Item>
                        <Form.Item
                            {...layoutHeading}
                            label="Tiêu đề phụ"
                            name="boxChat_subTitle"
                            tooltip="Chữ đứng dưới tiêu đề chính của bot"
                        >
                            <Input showCount maxLength={200} placeholder="Nhập tiêu đề phụ" />
                        </Form.Item>
                        <Form.Item
                            {...layoutHeading}
                            label="Câu chào Website"
                            name="logo_greeting"
                            tooltip="Câu chào sẽ được hiển thị ở Logo của Embediing nhúng Website"
                        >
                            <Input showCount maxLength={200} placeholder="Nhập nội dung" />
                        </Form.Item>

                        <Row className="pt-2">
                            <Col span={12}>
                                <Typography.Text className="font-medium">Màu hiển tiêu đề của bot</Typography.Text>
                                <Form.Item
                                    className="my-4"
                                    {...layoutItem}
                                    name="boxChat_backgroundColor"
                                    label="Màu nền"
                                    tooltip="Màu nề hiển thị tiêu đề"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                                <Form.Item
                                    {...layoutItem}
                                    name="preview_title_color"
                                    label="Màu chữ"
                                    tooltip="Màu chữ hiển thị trên nội dung tiêu đề"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                                <Typography.Text className="font-medium">Màu nút chức năng</Typography.Text>
                                <Form.Item
                                    {...layoutItem}
                                    className="my-4"
                                    name="preview_send_button_color"
                                    label="Màu nền Button"
                                    tooltip="Màu nền gửi của nút chức năng"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                                <Form.Item
                                    {...layoutItem}
                                    name="boxChat_colorWidgetBg"
                                    label="Màu nền icon"
                                    tooltip="Màu icon của embedding tích hợp website"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Typography.Text className="font-medium">Màu tin nhắn người dùng</Typography.Text>
                                <Form.Item
                                    {...layoutItem}
                                    className="my-4"
                                    name="userM_backgroundColor"
                                    label="Màu nền"
                                    tooltip="Màu nền của tin nhắn người dùng"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                                <Form.Item
                                    {...layoutItem}
                                    name="userM_color"
                                    label="Màu chữ"
                                    tooltip="Màu chữ của tin nhắn  người dùng"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>

                                <Typography.Text className="font-medium">Màu tin nhắn của Bot</Typography.Text>
                                <Form.Item
                                    {...layoutItem}
                                    className="my-4"
                                    name="botM_backgroundColor"
                                    label="Màu nền"
                                    tooltip="Màu nên của in nhắn bot"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                                <Form.Item
                                    {...layoutItem}
                                    name="botM_color"
                                    label="Màu chữ"
                                    tooltip="Màu chữ của tin nhắn  bot"
                                >
                                    <ColorPickerCustom />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </div>
        </Modal>
    );
};

export default ModalEditInfoAndColorEmbeding;
