import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { App, Modal, Form, Radio } from 'antd';
import Loading from '../components/base/loading';
import useStore from '../hooks/useStore';
import Router from '../router';

import aivBotContext from '../store/context/aivBotContext';
import { isCurrentTimeGreaterThan } from '../utils/sorting';
import { getUerInfor } from '../api/service';
import CreateBotMindmaid from '../components/common/createBot/createBotMindmaid';
import CreateBotGpts from '../components/common/createBot/createBotGpts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createBotState, listBankState, packageState } from '../atom';
import ModalEditInfoAndColorEmbeding from '../components/layout/modalEditInfoAndColorEmbeding';
import axios from 'axios';
import { getCookie } from '../utils/cookie';
import DrawerStoreBot from '../components/common/storebot';
import { getFromLocalStorage, saveToLocalStorage } from '../utils/localStore';
const Header = lazy(() => import('../components/layout/header'));
const Footers = lazy(() => import('../components/layout/footer'));
const BotMindMaid = lazy(() => import('../components/bot/index'));

function Wrapper() {
    const [state] = useStore();
    const [aivBotState, dispatchAivBot] = useContext(aivBotContext);
    const path = useLocation();
    const navigateTo = useNavigate();
    const [hidden, setHidden] = useState(aivBotState?.hidden);
    const [dataBot, setDataBot] = useState(null);
    const isToken = decodeToken(getCookie('access_token'));
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const messengerId = searchParams?.get('session_id');
    const [ibot, setIbot] = useState('mindmaid');
    const [form] = Form.useForm();
    const [iNoti, setINoti] = useState({ layout: false, package: false });
    const isCrreateBot = useRecoilValue(createBotState);
    const iMobileView = getFromLocalStorage('comfirm_setting', 'mobile_view');

    const pathsToCheck = [
        'reset-password',
        'login',
        'register',
        'price',
        'embed',
        'preview',
        'document',
        'chat-customer',
        'messenger',
        'privacy-policy',
        'store',
    ];

    useEffect(() => {
        if (isCrreateBot.type) {
            setIbot(isCrreateBot?.type);
        }
    }, [isCrreateBot]);

    const isPathIncluded = (paths) => paths.some((p) => path.pathname.includes(p));

    const isShowHeaderAndFooter = ![
        'reset-password',
        'preview',
        'embed',
        'chat-customer',
        'very-email',
        'buy-package',
        'very-personal',
        'app',
        'share',
        'login',
        'register',
        'price',
        'payment',
    ].some((item) => path.pathname.includes(item));

    const getChatSetting = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_V3_URL}/api/embed/bot/23e91aab-4bcf-4712-8f62-399f42904c97`,
            );
            if ([200, 201].includes(response.status)) {
                setDataBot(response.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            window.innerWidth <= 900 &&
            !iMobileView &&
            !path.pathname?.includes('embed') &&
            !path.pathname?.includes('preview') &&
            !path.pathname.includes('very') &&
            path.pathname.includes('app') &&
            !iNoti?.layout
        ) {
            Modal.info({
                title: 'Thông báo',
                content: (
                    <p className="text-gray-700">
                        Trang này chưa được điều chỉnh cho phù hợp với kích thước hiển thị hiện tại, nên sử dụng trên PC
                        và điều chỉnh kích thước phù hợp
                    </p>
                ),
                okText: 'Đồng ý',
                cancelText: '',
                okType: 'primary',
                onOk() {
                    setINoti((prev) => ({ ...prev, layout: true }));
                    saveToLocalStorage('comfirm_setting', 'mobile_view', true);
                },
            });
        }

        if (!path?.pathname.includes('preview')) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (isToken?.user_id && isPathIncluded(['login', 'register', 'reset-password'])) {
            navigateTo(messengerId ? `/messenger/${messengerId}` : '/app');

            return;
        }

        if (
            !isToken?.user_id &&
            !isPathIncluded(pathsToCheck) &&
            path.pathname !== '/' &&
            !path.pathname?.includes('buy-package') &&
            !path?.pathname?.includes('very-email') &&
            !path?.pathname.includes('very-personal')
        ) {
            navigateTo('/login');
            return;
        }
        if (isToken?.user_id && path.pathname?.includes('embed') && !path.pathname?.includes('preview')) {
            getUserPakage();
        }
        setHidden(path.pathname === '/' || isPathIncluded(['dashboard', 'document']));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.user?.access_token, path?.pathname]);

    useEffect(() => {
        if (path?.pathname === '/') {
            getChatSetting();
        }
        getBank();
    }, [path?.pathname]);

    const [dataUserInfo, setPackageUser] = useRecoilState(packageState);

    const getUserPakage = async () => {
        if (dataUserInfo) return;
        const quotarBot = await getUerInfor();
        setPackageUser(quotarBot);
        const isDate = isCurrentTimeGreaterThan(quotarBot?.expired_subscription_date);
        if (!isDate && !iNoti?.package) {
            Modal.confirm({
                title: 'Bạn chưa đăng ký gói/đã hết hạn',
                content: (
                    <div>
                        <p>Vui lòng đăng ký miễn phí 7 ngày (Basic)</p>
                        <p>Hoặc gói trả phí (Pro/Premium) đế sử dụng và trải nghiệm tất cả các tính năng</p>
                    </div>
                ),
                okText: 'Đăng ký',
                cancelText: 'Huỷ',
                okType: 'primary',
                onOk() {
                    setINoti((prev) => ({ ...prev, package: true }));
                    navigateTo('/price');
                },
                onCancel() {},
            });
        }
    };

    const [listBank, setListBank] = useRecoilState(listBankState);

    const getBank = async () => {
        if (listBank?.length > 0) return;
        try {
            const res = await axios.get('https://api.vietqr.io/v2/banks');
            if (res.status <= 205) {
                const newdata = res.data.data.map((i) => ({
                    ...i,
                    label: i.shortName,
                    value: i.shortName,
                }));
                setListBank(newdata);
            }
        } catch (error) {}
    };

    return (
        <Suspense fallback={<Loading />}>
            <div id="App">
                <App>
                    <DrawerStoreBot />
                    {isShowHeaderAndFooter && <Header isToken={isToken} />}

                    {hidden && aivBotState?.hidden && (
                        <BotMindMaid
                            setDataBot={setDataBot}
                            dataBot={dataBot}
                            dispatchAivBot={dispatchAivBot}
                            aivBotState={aivBotState}
                            loading={loading}
                        />
                    )}

                    <div>
                        <Router />
                    </div>
                    <Modal
                        title="Bắt đầu tạo BOT của bạn"
                        centered
                        open={isCrreateBot?.isOpen}
                        footer={[]}
                        closable={false}
                        width={750}
                    >
                        <div className="max-h-[calc(100vh-14em)] overflow-y-auto thin-scroll">
                            <Form
                                form={form}
                                initialValues={{
                                    layout: ibot,
                                }}
                                style={{ marginTop: 25 }}
                            >
                                <Form.Item label="Chọn loại bot">
                                    <Radio.Group onChange={(e) => setIbot(e.target.value)} value={ibot}>
                                        <Radio.Button value="mindmaid">Bot Mindmaid</Radio.Button>
                                        <Radio.Button value="gpts">Bot GPTs(Nâng cao)</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                            {ibot === 'mindmaid' ? <CreateBotMindmaid /> : <CreateBotGpts />}
                        </div>
                    </Modal>
                    <ModalEditInfoAndColorEmbeding />
                    {isShowHeaderAndFooter && !path?.pathname?.includes('store') && <Footers />}
                </App>
            </div>
        </Suspense>
    );
}

export default Wrapper;
