import React, { useState } from 'react';
import { Button, Modal, Tooltip, Spin, Input, Select, Space } from 'antd';
import { IoIosAddCircleOutline } from 'react-icons/io';
import ontionData from '../../../utils/data.json';

const TemplatePrompt = ({ form, onValuesChange, iCreate = false, type = 'system_prompt' ,title="Lênh mẫu"}) => {
    const [open, setOpen] = useState(false);
    const [newPrompot, setNewPrompt] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const handleSavePrompt = () => {
        const payload = {
            [type]: newPrompot,
        };
        if (!iCreate) {
            onValuesChange(payload);
        }
        form.setFieldsValue(payload);
        setOpen(false);
        setNewPrompt('');
    };

    const onChange = (value) => {
        setNewPrompt(value);
    };

    const closeModal = () => {
        setOpen(false);
        setNewPrompt('');
    };

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Tooltip title={type === 'instructions' ? 'Mô tả mẫu' : 'Lệnh điều khiển mẫu'}>
                <Space
                    className="z-30 text-sm cursor-pointer font-medium text-primary"
                    type="text"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <IoIosAddCircleOutline size={18} />
                    {title || ''}

                </Space>
            </Tooltip>
            <Modal
                title="Lệnh điều khiển mẫu"
                centered
                open={open}
                onOk={() => handleSavePrompt()}
                onCancel={() => closeModal()}
                footer={[
                    <Button onClick={() => closeModal()}>Huỷ bỏ</Button>,
                    <Button type="primary" className="bg-primary" onClick={() => handleSavePrompt()}>
                        Lưu lại
                    </Button>,
                ]}
                okText="Lưu lại"
                cancelText="Huỷ bỏ"
                width={700}
            >
                <Spin spinning={false} delay={100}>
                    <div className="h-[calc(100vh-20em)] w-full relative overflow-y-auto thin-scroll">
                        {!newPrompot && (
                            <p className="absolute top-1/2 right-1/3 z-40">
                                Vui lòng chọn {type === 'instructions' ? 'mô tả' : 'lệnh điều khiển'} mẫu
                            </p>
                        )}

                        <div className="flex justify-end">
                            <Select
                                variant="filled"
                                className="mb-2 w-[250px]"
                                onChange={onChange}
                                showSearch
                                value={newPrompot || null}
                                placeholder={type === 'instructions' ? 'Chọn mô tả' : 'chọn lệnh điều khiển mẫu'}
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={ontionData || []}
                            />
                        </div>
                        <Input.TextArea
                            disabled={!newPrompot}
                            style={{
                                resize: 'none',
                                height: 460,
                                border: isFocused ? '1px solid #d9d9d9' : 'none',
                                borderRadius: isFocused ? '4px' : 'none',
                                outline: 'none',
                            }}
                            onChange={(e) => setNewPrompt(e.target.value)}
                            value={newPrompot}
                            variant="filled"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default TemplatePrompt;
