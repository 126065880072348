import 'regenerator-runtime/runtime';
import '../src/assets/css/global.css';
// import 'antd/dist/antd.css'; // Import CSS của antd
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import WrapperApp from './pages/wrapper.jsx';
import ProviderConfig from './store/provider/index.jsx';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RecoilRoot } from 'recoil';
const isDevelopment = process.env.NODE_ENV === 'development';

if (!isDevelopment) {
    Sentry.init({
        dsn: 'https://19cb5e332d00ad7cb50b98917375456b@sentry.aivgroup.vn/3',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <RecoilRoot>
            <ProviderConfig>
                <StrictMode>
                    <BrowserRouter>
                        <WrapperApp />
                    </BrowserRouter>
                </StrictMode>
            </ProviderConfig>
        </RecoilRoot>
    </GoogleOAuthProvider>,
);
