import React, { useEffect, useState } from 'react';
import { Button, Modal, Tooltip, message, Spin, Input } from 'antd';
import { MdAutoFixHigh } from 'react-icons/md';
import { ReloadOutlined } from '@ant-design/icons';
import axiosClient from '../../../api/axiosClient';
import { useParams } from 'react-router-dom';
import { notiDetailError } from '../../../utils/notiMessage';

const AutoCustomPrompt = ({ iCreate = false, form, prompt, onValuesChange, apikey = '', type = 'system_prompt' }) => {
    const [open, setOpen] = useState(false);
    const { bot_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [newPrompot, setNewPrompt] = useState('');

    const handleSavePrompt = () => {
        const payload = {
            [type]: newPrompot,
        };

        if (!iCreate) {
            onValuesChange(payload);
        }
        form.setFieldsValue(payload);
        setOpen(false);
        setNewPrompt('');
    };

    const closeModal = () => {
        setOpen(false);
        setNewPrompt('');
    };

    const getAutoPrompt = async () => {
        setLoading(true);
        try {
            const res = await axiosClient.post(`/optimize-system-prompt`, {
                ...(!iCreate && { bot_id: bot_id }),
                ...(apikey && { user_api_key: apikey }),
                prompt: prompt,
            });
            if (res.status <= 205) {
                setNewPrompt(res.data.answer);
                setLoading(false);
                if (res.data?.detail) {
                    message.error(res.data.detail);
                }
                return;
            }
            if (res.data?.detail) {
                if (res.data?.detail?.includes('Error code:')) {
                    notiDetailError(res);
                } else {
                    message.error(res.data?.detail);
                }
            } else {
                message.warning('Đã có lỗi xảy ra');
            }
            setLoading(false);
        } catch (error) {
            message.warning('Chức năng đang bảo trì');
        }
    };

    useEffect(() => {
        if (open) {
            getAutoPrompt();
        }
    }, [open]);

    const handleOpenModal = () => {
        if (iCreate) {
            if (!apikey) {
                form.setFields([
                    {
                        name: 'user_api_key',
                        errors: ['Vui lòng nhập OpenAI key trước'],
                    },
                ]);
            }
            if (!prompt) {
                form.setFields([
                    {
                        name: 'system_prompt',
                        errors: ['Vui lòng nhập Lệnh điều khiển!'],
                    },
                ]);
            }

            if (apikey && prompt) {
                setOpen(true);
            }
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            <Tooltip title="Tự động tối ưu lệnh điều khiển của bạn">
                <Button
                    className="z-30 text-sm font-medium text-primary"
                    icon={<MdAutoFixHigh />}
                    type="text"
                    size="small"
                    onClick={() => handleOpenModal()}
                >
                    Tối ưu
                </Button>
            </Tooltip>
            <Modal
                title={
                    <div>
                        <p>Tự động tối ưu lệnh điều khiển</p>
                        <Button
                            disabled={loading}
                            onClick={getAutoPrompt}
                            size="small"
                            className="absolute right-12 font-medium top-6 text-primary"
                            icon={<ReloadOutlined />}
                            type="text"
                        >
                            Làm mới
                        </Button>
                    </div>
                }
                centered
                open={open}
                onOk={() => handleSavePrompt()}
                onCancel={() => closeModal()}
                footer={[
                    <Button onClick={() => closeModal()}>Huỷ bỏ</Button>,
                    <Button disabled={loading} type="primary" className="bg-primary" onClick={() => handleSavePrompt()}>
                        Lưu lại
                    </Button>,
                ]}
                okText="Lưu lại"
                cancelText="Huỷ bỏ"
                width={700}
            >
                <Spin spinning={loading} delay={100}>
                    <div className="h-[calc(100vh-20em)]">
                        <Input.TextArea
                            style={{ resize: 'none', height: 480 }}
                            onChange={(e) => setNewPrompt(e.target.value)}
                            value={newPrompot}
                        />
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default AutoCustomPrompt;
